<template>
  <section>
    <div class="banner">
      <div class="banner-text">
        <div class="container">
          <h2 class="banner-title">PROGRAMS</h2>
          <h2 class="banner-sub-title">Honorary President</h2>
        </div>
      </div>
    </div>
    <section class="main-container">
      <div class="container">
        <div class="left">
          <div class="div">
            <div class="widget">
              <h3 class="widget-title"><span id="main-title-name">PROGRAMS</span></h3>
              <ul class="nav-tabs">
                <li :class="$route.name===nav.urlName ? 'active':'' " v-for="(nav, index) in programNav" :key="index"><a @click="redirectUrl(nav)">{{ nav.title }}</a></li>
              </ul>
            </div>
          </div>
        </div>
        <div class="right">
          <h3 class="sub-title">Honorary President</h3>
          <a-row class="guest-list" :gutter="[{ xs: 10, sm: 20, md: 10, lg: 10 }, 30]">
            <a-col :sm="24" v-for="(item, index) in presidents" :key="index" style="overflow: hidden;">
              <div class="guest-item p-2.5 shadow-lg">
                <div class="flex" style="align-items: center;">
                  <img class="avatar mr-3.5" :src="item.avatar" />
                  <div>
                    <div class="guest-name mb-1.5 text-2xl" >{{ item.name }}</div>
                    <div class="mb:mb-2" style="font-style: italic;"><span>{{ item.position }}</span></div>
                    <div class="mb:mb-2" style="font-style: italic;">{{ item.site }}</div>
                  </div>
                </div>
              </div>
            </a-col>
          </a-row>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
export default {
  name:'officialSocialProgram',
   data() {
    return {
      programNav: [
        {title: "Program at a Glance", urlName: "programGlance" },
        {title: "Scientific Program", urlName: "scientificProgram" },
        {title: "Honorary President", urlName: "honoraryPresident" },
        {title: "Guest Faculty", urlName: "invitedGuests" },
        {title: "Social & Official Program", urlName: "officialSocialProgram" },
      ],
      presidents: [
        {
          name: "Shengshou Hu",
          avatar: "https://drvoicedev.oss-cn-beijing.aliyuncs.com/drvoice/server/uploadfile/2024/05/08/4f03d92f29c64ecaac1ff40921e0bafd.png",
          position: "",
          site: "Fuwai Hospital, Chinese Academy of Medicine Science and Perking Union Medical College",
        },
        {
          name: "Jie He",
          avatar: "https://drvoicedev.oss-cn-beijing.aliyuncs.com/drvoice/server/uploadfile/2024/05/08/4cbd1146b5e6420ea1bac41af7040f6d.png",
          position: "",
          site: "Cancer Hospital of Chinese Academy of Medical Sciences",
        },
        {
          name: "Jun Wang",
          avatar: "https://drvoicedev.oss-cn-beijing.aliyuncs.com/drvoice/server/uploadfile/2024/05/08/12d5ed3feb49461b96351804319ff081.png",
          position: "",
          site: "Peking University People's Hospital, Peking University",
        },
        {
          name: "Jian Zhuang",
          avatar: "https://drvoicedev.oss-cn-beijing.aliyuncs.com/drvoice/server/uploadfile/2024/05/08/e239953edf7d4fe6b9ec2ab8d74cf8d3.png",
          position: "",
          site: "Guangdong General Hospital,South China University of Technology",
        },
      ]
    }
  },
  methods: {
    redirectUrl(nav) {
      if (nav.urlName === "programGlance") {
        return window.open("https://mm.sciconf.cn/en/minisite/program-view/21452","Program at a Glance");
      }
      if (nav.urlName === "scientificProgram") {
        return window.open("https://ascvts2024.sciconf.cn/en/web/program/21452");
      }
      if (nav.urlName === "invitedGuests") {
        return window.open("https://ascvts2024.sciconf.cn/en/web/speaker/21452");
      }
      this.$router.push({name: nav.urlName})
    }
  }
}
</script>


<style lang="less" scoped>
.banner {
  position: relative;
  min-height: 300px;
  color: #fff;
  background-position: 50% 50%;
  background-size: cover;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.1) ), url('../../assets/img/about/banner.png');
  .banner-text {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    z-index: 1;
    transform: translateY(-50%);
    .container {
      min-height: 200px;
      max-width: 1320px;
      margin: 0 auto;
      text-rendering: optimizeLegibility;
      -webkit-font-smoothing: antialiased !important;
      .banner-title {
        color: #e8bd55;
        text-transform: uppercase;
        font-size: 58px;
        font-weight: 900;
      }
      .banner-sub-title{
        color: #fff;
        font-size: 48px;
        font-weight: 900;
      }
      @media (max-width:1320px) {
        padding: 0 15px;
      }
      @media (max-width:768px) {
        .banner-title {
          font-size: 48px;
        }
        .banner-sub-title {
          font-size: 38px;
        }
      }
    }
  }
}
.main-container {
  .container {
    min-height: 200px;
    max-width: 1320px;
    margin: 0 auto;
    display: flex;
    .left {
      flex: 0 0 25%;
      max-width: 25%;
      padding: 0 15px;
      .div {
        height: 100%;
        border-right: solid 1px #ddd;
        border-left: solid 1px #ddd;
        background-color: #f8f9fa;
        .widget {
          padding: 30px 15px 40px;
          position: sticky;
          top: 70px;
          .widget-title{
            font-size: 27px;
            font-weight: 900;
            position: relative;
            padding-left: 30px;
            margin: 0 -15px;
            background-color: #19317e;
            color: #fff;
            text-transform: uppercase;
          }
          .nav-tabs li {
            color: #444444;
            line-height: normal;
            a {
              position: relative;
              color: #444444;
              padding: 15px 0 15px 15px;
              font-weight: 600;
              border-bottom: 1px solid #ddd;
              display: block;
              transition: all .3s;
              font-size: 18px;
            }
            &:hover a{
              color: #1B3287;
            }
            &.active a {
              color: #1B3287;
              &::before {
                font-family: "iconfont";
                content:'\e6ae';
                position: absolute;
                top: 50%;
                right: 0;
                transform: translateY(-50%);
              }
            }
          }
        }
      }
    }
    .right {
      width: 75%;
      min-height: 700px;
      flex: 0 0 75%;
      position: relative;
      padding: 0 15px 200px;
      .sub-title {
        font-weight: 900;
        font-size: 27px;
        line-height: normal;
        margin: 30px 0px 20px 0px;
        padding-bottom: 10px;
        border-bottom: solid 1px #ddd;
      }
    }
    @media (max-width:768px) {
      .left {
        display: none;
      }
      .right {
        flex: 1;
        padding: 0 15px 40px;
        .bottom {
          display: block;
          .bottom-lt {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }
  }
}
.guest-list {
      margin: 0!important;
    }
    .guest-item {
      &:hover {
        background-color: rgba(254, 242, 203, 0.5);
      }
      position: relative;
      border: solid 1px #ccc;
      border-radius: 5px;
      /deep/ .highlight {
        background-color: #FFFF88;
      }
      .download {
        display: flex;
        align-items: center;
        background: linear-gradient(90deg, #24A7F2 0%, #1B3288 100%);
        font-weight: 500;
        border-top-right-radius: 5px;
        position: absolute;
        right: 0;
        top: 0;
        padding: 2px 10px;
        color: #ffffff;
        font-size: 18px;
        font-family: "Roboto Condensed", sans-serif;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        .icon {
          font-size: 15px;
          color: #ffffff;
          margin-right: 2px;
        }
      }
      .office {
        font-size: 15px;
        line-height: 130%;
        font-style: italic;
        min-height: 40px;
        color: #444444;
      }
      .avatar {
        border-radius: 50%!important;
        width: 100px;
        height: 100px;
        border: 1px solid #dee2e6;
        padding: 0 2px;
      }
      .role {
        span {
          padding: 0.25em 0.4em;
          color: white;
          border-radius: 0.25rem;
        }
        span + span {
          margin-left: 0.25rem;
        }
      }
    }
</style>
